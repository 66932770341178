/* ----------------------------------------------------------------------------------------------------- */


/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import '~@ctrl/ngx-emoji-mart/picker';
.surthex-green {
    color: #569748 !important;
}

.surthex-red {
    color: #aa0d0d !important;
}

.surthex-bg-green {
    background: #569748 !important;
}

.surthex-bg-red {
    background: #aa0d0d !important;
}

.surthex-bg-black {
    background: #172228;
}

.surthex-bg-gray-light {
    background: #f5f5f5;
}

.surthex-bg-gray-strong {
    background: #404d53;
}

.surthex-gray-text {
    color: #adb5bd;
}

.surthex-gray-strong {
    color: #404d53;
}

.surthex-sky-blue {
    color: #30ACFB
}

.surthex-mexican-rose {
    color: #EB1862;
}

.surthex-orange {
    color: #EB582D;
}

.surthex-yellow {
    color: #ebc22d;
}

.surthex-blue-rev {
    background-color: #94D6FF;
}

.itz-search-rounded>div>div {
    border-radius: 30px !important;
}

.itz-links {
    color: #b7bec5;
}

.itz-follow-btn {
    background: #97d839;
    padding: 8px 20px;
    color: white;
    border-radius: 8px;
}

.itz-edit-btn {
    background-color: #eeeeee !important;
    color: #454a50 !important;
}

.itz-edit-btn-xl {
    background-color: #eeeeee !important;
    color: #454a50 !important;
    padding: 8px 20px;
    border-radius: 8px;
}

.itz-feed-title {
    color: #212529;
}

.itz-feed-subtitle {
    color: #b4bcc3;
}

.itz-feed-edit {
    color: #569748;
    font-weight: bold;
}

.itz-account-membership {
    color: #505163;
}

.itz-settings-update {
    background: #343a40 !important;
    color: white !important;
    border-radius: 4px !important;
}

.itz-green-btn {
    background-color: var(--fuse-primary) !important;
    color: white !important;
}

.itz-video {
    background-color: #212529;
    text-align: -webkit-center;
}

.itz-img {
    background-color: #ffffff;
}

.itz-color-post {
    color: #000000;
}

.itz-color-comment {
    color: #b6b6be;
}

.itz-color-comment-title {
    color: #212529;
}

.mat-form-field-flex {
    border-color: #eeeeee !important;
}

.itz-text-title-grey {
    color: #222430;
}

.itz-text-cool-grey {
    color: #7a7a7a;
}

.itz-text-strong-grey {
    color: #3c3f43 !important;
}

.itz-text-slate-grey {
    color: #505163 !important;
}

.itz-link-menu:hover {
    background-color: #f0f2f4;
}

.itz-border-seller {
    border-color: #66d6a2 !important;
    border-style: solid;
    border-width: 0.25rem;
}

.itz-border-user {
    border-color: #bf994c !important;
    border-style: solid;
    border-width: 0.25rem;
}

.itz-bg-cool-grey {
    background-color: #b6b6be !important;
}

.itz-show-cart {
    color: #b6b6be !important;
}

.mat-simple-snackbar-action {
    color: #569748 !important;
}

.bg-green-cake {
    background-color: #c8f1bf;
}

.surthex-chip-btn {
    background: transparent !important;
    border: solid 1px #c8f1bf !important;
}

.surthex-chip-btn>button>mat-icon {
    color: #aa0d0d !important;
}

.surthex-bg-lemmon {
    background: #c8f1bf;
}

.surthex-bg-unread {
    background: #F0FBED;
}

.itz-form-border-none>div.mat-form-field-wrapper {
    margin-bottom: 0px !important;
    padding: 0px !important;
}

.itz-form-border-none>div.mat-form-field-wrapper>div.mat-form-field-flex {
    border: none !important;
    box-shadow: none !important;
}

.itz-moment-card-text {
    background: rgb(25, 87, 135);
    background: linear-gradient(293deg, rgba(25, 87, 135, 1) 0%, rgba(7, 129, 224, 1) 34%, rgba(80, 134, 212, 1) 100%);
}

.itz-moment-card-image {
    background: rgb(86, 151, 72);
    background: linear-gradient(56deg, rgba(86, 151, 72, 1) 0%, rgba(103, 214, 102, 1) 34%, rgba(169, 214, 102, 1) 100%);
}

//OWL
.owl-nav {
    margin-top: 0px !important;
    position: absolute;
    top: 45%;
    width: 100%;
}

.owl-prev {
    position: absolute;
    left: 0px;
}

.owl-next {
    position: absolute;
    right: 0px;
}

.h-detail-moment {
    height: 34rem !important;
}

span.mat-placeholder-required {
    color: red;
}

.itz-alert-post-product>mat-dialog-container {
    padding: 0px;
}

.itz-fix-scroll {
    padding-bottom: 12rem !important;
}

.itz-post-detail-container {
    background: black;
    padding: 1rem 1rem !important;
    min-height: 36rem !important;
    width: 100% !important;
}

.itz-post-detail-image {
    max-height: 32rem;
    object-fit: scale-down;
}

.itz-h-32 {
    height: 32rem;
}

.itz-max-h-32 {
    max-height: 32rem;
}